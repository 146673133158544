div.thanks-container {
  height: 32em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gracias {
  text-align: center;
  margin-bottom: 1em;
}

@media screen and (max-width: 991px) {
  div.thanks-container {
    height: 23em;
  }
}

