* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box;
}


/* START FONTS */ 

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Book.eot');
  src: url('./fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gotham-Book.woff2') format('woff2'),
      url('./fonts/Gotham-Book.woff') format('woff'),
      url('./fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.eot');
  src: url('./fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gotham-Bold.woff2') format('woff2'),
      url('./fonts/Gotham-Bold.woff') format('woff'),
      url('./fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-BoldItalic.eot');
  src: url('./fonts/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gotham-BoldItalic.woff2') format('woff2'),
      url('./fonts/Gotham-BoldItalic.woff') format('woff'),
      url('./fonts/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Black.eot');
  src: url('./fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gotham-Black.woff2') format('woff2'),
      url('./fonts/Gotham-Black.woff') format('woff'),
      url('./fonts/Gotham-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Thin.eot');
  src: url('./fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gotham-Thin.woff2') format('woff2'),
      url('./fonts/Gotham-Thin.woff') format('woff'),
      url('./fonts/Gotham-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* END FONTS */

html {
  scroll-behavior: smooth;
}

body {
  font: normal 15px 'Gotham', Arial, Helvetica, sans-serif;
  color: #0D1433;
  -webkit-font-smoothing: antialiased;
}

section{
  padding: 50px 0;
}

h1 {
  font-size: 90px;
  font-weight: 900;
}

h2 {
  font-size: 65px;
  font-weight: 900;
  margin-bottom: 15px;
}

h3 {
  font-size: 45px;
  margin-bottom: 30px;
}

p {
  font-size: 24px;
}

a{
  text-decoration: none;
  cursor: pointer;
}

input, select {
  border-radius: 0;
}

input, select, textarea {
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
}

.hidden{
  display: none;
}

.button {
  height: 52px;
  border: 0;
  font-size: 28px;
  border-radius: 50px;
  color: #fff;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  transition: filter 0.2s;
  padding: 0 22px;
  cursor: pointer;
  position: relative;
  top: 0;
  transition: top 0.1s;
}

.button:active {
  top: 1px;
}

.button:hover {
  filter: brightness(90%);
}

.button-blue {
  background-color: #222D65;
}

.button-orange {
  background-color: #F85100;
}

.button-yellow {
  background-color: #EFAB00;
}

/* DESKTOP */
@media screen and (max-width: 1199px){
  
  
}

/* MEDIUM DEVICES */
@media screen and (max-width: 991px) {
  h1 {
    font-size: 45px;
  }

  h2{
    font-size: 45px;
  }

  h3{
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }
}

/* LANDSCAPE PHONES */
@media screen and (max-width: 768px) {

  .container{
    padding: 0 15px;
  }

  body {
      width: 100%;
  }

  .button {
    font-size: 25px;
  }
}